import { getPropertyBuyers, updatePropertyBuyers } from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'

const schema = [
  {
    type: 'autocomplete',
    autoCompleteType: 'contact',
    multiple: true,
    name: 'buyers',
    label: 'Kopers',
    placeholder: 'Selecteer kopers'
  }
]

const actions = {
  async load (propertyId) {
    try {
      const response = await getPropertyBuyers(propertyId)
      // FIXME: [BE] the response is an array, it should be an object with a key value pair. { buyers: [...] }
      return { buyers: response.data }
    } catch (error) {
      errorModal('Kopers kunnen niet worden geladen')
      throw error
    }
  },
  async submit (propertyId, data) {
    try {
      const { buyers } = data
      const relation_ids = buyers.map(buyer => buyer.id)
      const response = await updatePropertyBuyers(propertyId, { relation_ids })
      successModal('Kopers zijn succesvol bijgewerkt')
      return response
    } catch (error) {
      if (error?.response?.data?.code?.[0] === 'ANOTHER_CONTACT_WITH_SAME_EMAIL_ADDRESS_HAS_USER_ACCOUNT') {
        const email = error?.response?.data?.message?.[0] || '-'
        errorModal(`Kan kopers niet aanpassen. Er bestaat een ander contact met e-mailadres ${email} en een gekoppeld klantenportaal. Gelieve dit contact te selecteren of de contacten samen te voegen.`)
      } else {
        errorModal('Kan kopers niet aanpassen, gelieve opnieuw te proberen.')
      }
      throw error
    }
  }
}

export default {
  heading: 'Kopers',
  schema,
  actions
}
